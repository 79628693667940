exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexdev-js": () => import("./../../../src/pages/indexdev.js" /* webpackChunkName: "component---src-pages-indexdev-js" */),
  "component---src-pages-markdown-maker-js": () => import("./../../../src/pages/markdown-maker.js" /* webpackChunkName: "component---src-pages-markdown-maker-js" */),
  "component---src-pages-markdown-remark-fields-slug-js": () => import("./../../../src/pages/{MarkdownRemark.fields__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-fields-slug-js" */),
  "component---src-pages-tags-all-js": () => import("./../../../src/pages/tags-all.js" /* webpackChunkName: "component---src-pages-tags-all-js" */),
  "component---src-pages-tags-finder-js": () => import("./../../../src/pages/tags-finder.js" /* webpackChunkName: "component---src-pages-tags-finder-js" */),
  "component---src-pages-testaa-js": () => import("./../../../src/pages/testaa.js" /* webpackChunkName: "component---src-pages-testaa-js" */),
  "component---src-templates-child-category-js": () => import("./../../../src/templates/child-category.js" /* webpackChunkName: "component---src-templates-child-category-js" */),
  "component---src-templates-parent-category-js": () => import("./../../../src/templates/parent-category.js" /* webpackChunkName: "component---src-templates-parent-category-js" */),
  "component---src-templates-single-tag-page-js": () => import("./../../../src/templates/single-tag-page.js" /* webpackChunkName: "component---src-templates-single-tag-page-js" */),
  "component---src-templates-sub-index-js": () => import("./../../../src/templates/sub-index.js" /* webpackChunkName: "component---src-templates-sub-index-js" */)
}

