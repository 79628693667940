//テーマは最上部で読み込まねばならない
//テーマを読む場合はgatsby-browser.jsで読むことで一番の親として読める

import React from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import CssBaseline from '@mui/material/CssBaseline'; //ブラウザの差異をなくす。これがないとthemeにdarkを適用しても全体が変化しない

let theme = createTheme({

    //テーマ
    //※公式のテーマを作るツールを使うと良い theme builderの項を参考
    // https://mui.com/customization/theming/
    // https://bareynol.github.io/mui-theme-creator/
    //useMediaQueryを使用時に初回リードでかならずfalseになるやつを解消
    //これを設定しないとuseMediaQueryを使ってgoogle広告がwidth=0でも描画しようとして画面がきえてしまう
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        //スクロールバーを変更
        MuiCssBaseline: {
            styleOverrides: `
            ::-webkit-scrollbar {
                width: 18px;
            },
            ::-webkit-scrollbar-thumb {
                background-color: #276976;
                border-radius: 8px;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                background-clip: padding-box;
            },
            ::-webkit-scrollbar-track {
                margin-top: 4px;
                margin-bottom: 4px;
            }
            `
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#2698A9',
            light: '#276976',
            dark: '#00ACC1',
        },
        secondary: {
            main: '#C64191',
            sub: '#ACFCD9',
            mainDark: '#94316C',
        },
        hosoku: {
            default: '#9BD1E6',
            secondary: '#B2B9BF',
        },
        background: {
            default: '#323743',
            paper: '#272B34',
            code: '#17191F',
            tableBorder: '#8C95A9',
            listBorder: '#423F4D',
            quotation: '#324043',
            listBack: '#2A2E38',
        },
        text: {
            secondary: '#B2B9BF',
            primary: '#FFFFFF',
        },
        divider: '#52555D',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            right_ads: 1750,
        },
    },
})

const MyTheme = ({ children }) => {

    //ドロワの出し入れ用
    //重要：初期値を変数にした場合ビルド時に初期値が上書きされるだけなので
    //ここの初期値はtrueかfalseのどちらかにする(Nullも使えなくはない)
    const [mobileOpen, setMobileOpen] = React.useState(true)

    //サイト初回進入時判定
    // const [isFirstEnter, setFirstEnter] = React.useState(true)
    const isFirstEnter = React.useRef(true) //useRefへ変更。この値を変更したときに再描画の必要がないため
    const setFirstEnter = (boolInput) => {
        isFirstEnter.current = boolInput
    }

    //ドロワの出し入れをテーマに入れる
    //これは様々なページから遷移するたびにドロワの状態を維持するためグローバル変数のように使う
    theme = createTheme(theme, {
        status: {
            isFirstEnter,
            setFirstEnter,
            mobileOpen,
            setMobileOpen,
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )

}

export default MyTheme