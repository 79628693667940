// Highlighting for code blocks
// import "prismjs/themes/prism.css"
import "prismjs/themes/prism-tomorrow.css"

import React from "react"
import MyTheme from "./src/components/my-theme.js"
import GoogleAnalytics from "./src/components/GoogleAnalytics"

//googleAnalytics用 page_viewを送信
//https://paulie.dev/posts/2022/07/how-to-use-gatsbys-script-api-with-google-analytics/
export const onRouteUpdate = ({ location }) => {
    if (process.env.NODE_ENV !== 'production') {
        return null;
    }
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    setTimeout(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', { page_path: pagePath });
        }
    }, 100);
    return true;
};

export const wrapRootElement = ({ element }) => {

    //テーマをすべての親にする
    return (
        <>
            <MyTheme>
                {element}
            </MyTheme>
            <GoogleAnalytics />
        </>
    )
}