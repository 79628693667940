
import { Script } from "gatsby"
import React from "react"

//googleAnalyticsのgtag版
//Scriptタグ対応のため自作
//スクリプト本体はテンプレートリテラルで囲む必要あり

//メモ off-main-threadの機能を使うとたぶんはなくなるのだろうけど、リダイレクト設定までちゃんとやらないといけない模様
//netlify.tomlでリダイレクトの設定も行ったが動作せず
//https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/
//またScriptタグはヘッダに適用できないらしい。アナリティクスもヘッダに書くよう指示されているが、別にそうでなくても動作はするらしい。

//バージョン4の新しいやつ
const GoogleAnalytics = () => {

    return (
        <>
            <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID4}`}
                // strategy="off-main-thread"
                // forward={[`gtag`]}
            />
            <Script id="gtag-config"
                // strategy="off-main-thread"
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    window.gtag = function gtag(){ window.dataLayer.push(arguments);}
                    gtag('js', new Date()); 
                    gtag('config', '${process.env.GOOGLE_ANALYTICS_ID4}', { send_page_view: false })`
                  }}
            />
        </>
    )
}

//こちらは現在使用していない古い方(保存用)
// const GoogleAnalyticsOld = () => {

//     return (
//         <>
//             <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID}`}
//             // strategy="off-main-thread"
//             // forward={[`gtag`]}
//             />
//             <Script id="gtag-config"
//             // strategy="off-main-thread"
//             >
//                 {`
//                     window.dataLayer = window.dataLayer || [];
//                     function gtag(){dataLayer.push(arguments);}
//                     gtag('js', new Date());

//                     gtag('config', '${process.env.GOOGLE_ANALYTICS_ID}' )
//                 `}
//             </Script>
//         </>
//     )
// }



export default GoogleAnalytics